body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h4 {
  background-color: aquamarine;
  padding: 30px;
  margin: 0;
  border-bottom: 2px solid black;

}

a {
  color: #2a3ac7;
}

h4 a {
  color: #000;
}

form {
  margin: 80px 30px 80px 30px;
}

.success-message {
  margin: 80px 30px 80px 30px;

}